import { FC } from 'react';
import { useHistory, useLocation } from 'react-router';
import QRCode from 'react-qr-code';
import { Button } from '@mui/material';
import { useQuery, gql } from '@apollo/client';
import moment from 'moment';

import { getStatusInformation } from 'constants/index';

import DefaultLayout from 'layouts/DefaultLayout';

import FoodIcon from 'components/FoodIcon';
import Divider from 'components/Divider';
import LoadingIndicator from 'components/LoadingIndicator';
import Title from 'components/Title';
import ErrorMessage from 'components/ErrorMessage';

import theme from './theme.module.scss';

export interface DishType {
  id: string;
  referenceId: string;
  name: string;
  price: number;
  isVegetarian: boolean;
  deliveryScheduledAt: string;
  quantity: number;
  options: {
    id: string;
    name: string;
  }[];
  combinations: {
    option: {
      id: string;
      name: string;
      price: number;
    };
    addOns: {
      id: string;
      name: string;
      price: number;
    }[];
  }[];
  addOns: {
    id: string;
    name: string;
  }[];
  category: {
    id: string;
    name: string;
  };
}

const ORDER = gql`
  query getOrderByReference($orderReferenceId: ID) {
    order(referenceId: $orderReferenceId) {
      id
      referenceId
      status
      totalPrice
      createdAt
      notes
      feedback {
        rating
        comments
      }
      deliveryScheduledAt
      vendor {
        name
      }
      dishes {
        id
        name
        price
        isVegetarian
        quantity
        category {
          id
          name
        }
        combinations {
          option {
            id
            name
            price
          }
          addOns {
            id
            name
            price
          }
        }
      }
    }
  }
`;

const DishItem: FC<{
  name: string;
  price: number;
  quantity?: number;
  isVegetarian?: boolean;
}> = ({ name, price, quantity, isVegetarian }) => (
  <div className={theme.dishCard}>
    {isVegetarian !== undefined ? <FoodIcon isVeg={isVegetarian} /> : null}
    <div className={theme.informationContainer}>
      <div className={theme.left}>
        <p className={theme.name}>
          {name} {quantity ? `x ${quantity}` : null}
        </p>
      </div>
      <div className={theme.right}>
        <p className={theme.price}>₹ {quantity ? price * quantity : price}</p>
      </div>
    </div>
  </div>
);

const OrderSummary: FC<{
  dishes: DishType[];
  notes: string;
  vendorName: string;
  totalPrice: number;
  createdAt: string;
  deliveryScheduledAt: string;
}> = ({ dishes, notes, vendorName, totalPrice, createdAt, deliveryScheduledAt }) => {
  const dishesGroupedByCategory: {
    [category: string]: DishType[];
  } = dishes.reduce(function (acc, curr) {
    const category = curr.category.name;
    if (!acc.hasOwnProperty(category)) {
      acc[category] = [];
    }
    acc[category].push(curr);
    return acc;
  }, {});

  const categories = Object.keys(dishesGroupedByCategory);
  const orderTime = moment(createdAt).format('D MMMM YYYY, h:mm A');

  return (
    <div className={theme.summaryContainer}>
      <p className={theme.subHeading}>ORDER SUMMARY</p>
      <p className={theme.vendorName}>{vendorName}</p>
      <p className={theme.orderTime}>{orderTime}</p>
      {categories.map((category) => (
        <div key={category}>
          {dishesGroupedByCategory[category].map((dish) => (
            <div key={dish.id}>
              <DishItem
                name={dish.name}
                price={dish.price}
                quantity={dish.quantity}
                isVegetarian={dish.isVegetarian}
              />
              {dish.combinations &&
                dish.combinations.map((combination, i) => (
                  <div key={i}>
                    {combination.option ? (
                      <DishItem name={combination.option.name} price={combination.option.price} />
                    ) : null}
                    {combination.addOns.map((addOn) => (
                      <DishItem key={addOn.id} name={addOn.name} price={addOn.price} />
                    ))}
                  </div>
                ))}
            </div>
          ))}
        </div>
      ))}
      <Divider />
      <div className={theme.totalContainer}>
        <span className={theme.label}>Total</span>
        <span className={theme.value}>₹ {totalPrice}</span>
      </div>
      {notes ? (
        <>
          <Divider />
          <div className={theme.notesContainer}>
            <p className={theme.subHeading}>NOTES</p>
            <p>{notes}</p>
          </div>
        </>
      ) : null}
      {/* {scheduledTime ? (
        <p className={theme.orderTime}>Delivery Scheduled At {scheduledTime}</p>
      ) : null} */}
    </div>
  );
};

function useURLQueryParams() {
  return new URLSearchParams(useLocation().search);
}

const GuestQR: FC = () => {
  const history = useHistory();
  const queryParams = useURLQueryParams();

  const orderReferenceId = queryParams.get('orderReferenceId') || '';

  const { loading, error, data, refetch } = useQuery(ORDER, {
    variables: {
      orderReferenceId,
    },
    pollInterval: 2000,
    fetchPolicy: 'network-only',
  });

  const renderContent = () => {
    if (loading) {
      return <LoadingIndicator />;
    }

    if (error || !data) {
      return <ErrorMessage refresh={refetch} />;
    }

    const order = data.order;

    const vendor = order.vendor;

    const dishes = order.dishes;

    const status = data && data.order && data.order.status ? data.order.status : '';

    const { image, message, label } = getStatusInformation[status.toLowerCase()];

    return (
      <div className={theme.contentContainer}>
        <div className={theme.statusContainer}>
          <p className={theme.header}>{label}</p>
          <p className={theme.referenceId}>Order ID # {order.referenceId}</p>
          <p className={theme.message}>{message}</p>
          {showImage(image, status)}
        </div>
        <OrderSummary
          dishes={dishes}
          notes={order.notes}
          vendorName={vendor.name}
          totalPrice={order.totalPrice}
          createdAt={order.createdAt}
          deliveryScheduledAt={order.deliveryScheduledAt}
        />
        {loading ? null : (
          <Button
            variant="outlined"
            size="small"
            className={theme.helpButton}
            onClick={() => history.push('Help')}
          >
            Need Help ?
          </Button>
        )}
      </div>
    );
  };

  function showImage(image: string, status: string) {
    if (status === 'READY')
      return (
        <div className={theme.imageContainer}>
          <QRCode value={orderReferenceId} bgColor="black" fgColor="#f5f5f5" />
        </div>
      );

    if (image)
      return (
        <div className={theme.imageContainer}>
          <img src={image} alt={status} className={theme.image} key={status} />
        </div>
      );

    return null;
  }

  return (
    <DefaultLayout>
      <Title name="Order Details" navigateBack={() => {}} />
      {renderContent()}
    </DefaultLayout>
  );
};

export default GuestQR;
