import { useReducer } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Cookies from 'js-cookie';

import CartContext from 'contexts/CartContext';
import LocationContext from 'contexts/LocationContext';

import ProtectedRoute from 'components/ProtectedRoute';

import reducer, { initialState } from 'reducers/cartReducer';

import Cart from './Cart';
import ChangeLocation from './ChangeLocation';
import Help from './Help';
import Home from './Home';
import OrderStatus from './OrderStatus';
import Vendor from './Vendor';
import ViewOrders from './ViewOrders';
import Profile from './Profile';
import TermsGallery from './TermsGallery';
import PersonalInformation from './PersonalInformation';
import LinkedAccounts from './LinkedAccounts';
import Login from './Login';
import Landing from './Landing';
import DeleteAccount from './DeleteAccount';
import GuestQR from './GuestQR';

export default function App() {
  const [cartState, cartDispatch] = useReducer(reducer, initialState);

  const [locationState, locationDispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case 'GET_CURRENT_LOCATION':
          return {
            ...prevState,
            currentFoodCourt: action.currentFoodCourt,
            isLoading: false,
          };
        case 'CHANGE_LOCATION':
          return {
            ...prevState,
            currentFoodCourt: action.currentFoodCourt,
          };
      }
    },
    {
      isLoading: true,
      currentFoodCourt: Cookies.get('currentFoodCourt')
        ? Cookies.get('currentFoodCourt')
        : '9c2aca04-49a3-4e48-9582-9d3240759657',
    }
  );

  const locationContext = {
    changeLocation: async (data) => {
      locationDispatch({
        type: 'CHANGE_LOCATION',
        currentFoodCourt: data.currentFoodCourt,
      });
    },
    currentFoodCourt: locationState.currentFoodCourt,
  };

  const cartContext = { cartState, cartDispatch };

  return (
    <CartContext.Provider value={cartContext}>
      <LocationContext.Provider value={locationContext}>
        <Router>
          <Switch>
            <Route path="/cart" component={Cart} />
            <Route path="/change-location" component={ChangeLocation} />
            <Route path="/help" component={Help} />
            <Route path="/delete-account" component={DeleteAccount} />
            <Route path="/orders/:orderId" component={OrderStatus} />
            <Route path="/qr" component={GuestQR} />
            <Route path="/vendor/:vendorId" component={Vendor} />
            <ProtectedRoute path="/orders" component={ViewOrders} />
            <ProtectedRoute path="/profile" component={Profile} />
            <ProtectedRoute path="/personal-information" component={PersonalInformation} />
            <ProtectedRoute path="/linked-accounts" component={LinkedAccounts} />
            <ProtectedRoute path="/terms-gallery" component={TermsGallery} />
            <Route path="/home" component={Home} />
            <Route path="/login" component={Login} />
            <Route path="/" component={Landing} />
          </Switch>
        </Router>
      </LocationContext.Provider>
    </CartContext.Provider>
  );
}
